import PropTypes from 'prop-types';
import * as React from 'react';
// import { MDXRenderer } from "gatsby-plugin-mdx";
import { Link, graphql } from 'gatsby';
import { HeaderPage } from '@bgea-js/design-library';
import Layout from '../components/Layout';
import * as Ui from '@bgea-js/react-ui';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';

//idea for optimization, might require <Suspense> wrapper
// const SingleDonation = React.lazy( () => import("@bgea/bgforms-templates/dist/templates/SingleDonation"));

function Page({ data }) {
  let meta = {
    socialTitle: 'Give Offline',
  };
  let styles = {};
  const projects = data?.projects?.nodes
    .filter((item) => item?.frontmatter?.image)
    .map((item) => ({
      ...item?.frontmatter,
      projectId: item?.frontmatter?.projectCode,
    }));
  projects.sort((a, b) => a?.order - b?.order);
  return (
    <Layout
      meta={meta}
      styles={styles}
      header={
        <HeaderPage title="Other Ways to Give" subtitle="" imageWidth={4} className='is-hidden-mobile'>
          <StaticImage src={'../images/header.jpg'} alt="Prayer Counseling" />
        </HeaderPage>
      }
    >
      <Ui.Section className="pt-3 pb-0">
        <Ui.Container>
          <Ui.Breadcrumb>
            <Ui.Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Ui.Breadcrumb.Item>
            <Ui.Breadcrumb.Item active>
              <Link to="#" aria-current="page">
                Other Ways to Give
              </Link>
            </Ui.Breadcrumb.Item>
          </Ui.Breadcrumb>
        </Ui.Container>
      </Ui.Section>
      <Ui.Section className="pt-3">
        <Ui.Container>
          <hr />
          <Ui.Columns>
            <Ui.Columns.Column>
              <Ui.Content>
                <Ui.Columns>
                  <Ui.Columns.Column>
                    <a href="https://billygraham.org/give/other-ways-to-give/honor-memory-giving/">
                      <img src="https://static.billygraham.org/sites/billygraham.org/uploads/pro/2013/07/29182741/Honor-Memory-Featured.jpg" />
                    </a>
                    <p class="title">
                      <a href="https://billygraham.org/give/other-ways-to-give/honor-memory-giving/">
                        Honor &amp; Memorial Giving
                      </a>
                    </p>
                    <p class="subheader">
                      Remember your loved one or friend in a way that can change
                      someone else’s life. Donate and send a personalized card
                      in honor or in memory of someone special.
                    </p>
                  </Ui.Columns.Column>
                  <Ui.Columns.Column>
                    <a href="https://billygraham.org/give/other-ways-to-give/non-cash-gifts/">
                      <img src="https://static.billygraham.org/sites/billygraham.org/uploads/pro/2015/01/29142651/Non-Cash-Gifts-Featured1.jpg" />
                    </a>
                    <p class="title">
                      <a href="https://billygraham.org/give/other-ways-to-give/non-cash-gifts/">
                        Non-Cash Gifts
                      </a>
                    </p>
                    <p class="subheader">
                      Support BGEA by donating non-cash items, including
                      cryptocurrency.
                    </p>
                  </Ui.Columns.Column>
                  <Ui.Columns.Column>
                    <a href="https://billygraham.org/give/other-ways-to-give/foundations-daf/">
                      <img src="https://static.billygraham.org/sites/billygraham.org/uploads/pro/2013/03/29182741/Foundations-Featured.jpg" />
                    </a>
                    <p class="title">
                      <a href="https://billygraham.org/give/other-ways-to-give/foundations-daf/">
                        Foundations
                      </a>
                    </p>
                    <p class="subheader">
                      We serve the granting objectives of public and private
                      foundations. If you lead a foundation with granting
                      objectives that we can help fulfill, contact our team
                      today.
                    </p>
                  </Ui.Columns.Column>
                  <Ui.Columns.Column>
                    <a href="https://billygraham.org/give/other-ways-to-give/pray/">
                      <img src="https://static.billygraham.org/sites/billygraham.org/uploads/pro/2013/03/29182739/Prayer-Featured.jpg" />
                    </a>
                    <p class="title">
                      <a href="https://billygraham.org/give/other-ways-to-give/pray/">
                        Pray
                      </a>
                    </p>
                    <p class="subheader">
                      Praying for the Billy Graham Evangelistic Association is
                      the most important way you can support our work.
                    </p>
                  </Ui.Columns.Column>
                </Ui.Columns>

                <h3>Donate by Mail</h3>
                <p>
                  Download and print out a donation form below or mail checks
                  directly to: 1 Billy Graham Parkway, Charlotte, NC 28201.
                </p>
                <p>
                  <a href="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/01/2023-Donation-Form-BY230MPDF.pdf">
                    <i class="foundicon-page"></i> Download Donation Form
                  </a>{' '}
                  <em>– PDF, 88 KB</em>
                </p>
                <p>
                  <a href="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/01/2023-BGEA-EFT-Form-BY230MEFT.pdf">
                    <i class="foundicon-page"></i> Download Monthly Donation
                    Form
                  </a>{' '}
                  <em>– PDF, 2.4 MB</em>
                </p>

                <h3>Donate by Phone</h3>
                <p>
                  Call 1-877-247-2426, Monday through Friday, 8:30 a.m.–11:00
                  p.m. ET; Saturday, 9:00 a.m.–11:00 p.m. ET; closed on Sunday.
                </p>
              </Ui.Content>
            </Ui.Columns.Column>
          </Ui.Columns>
        </Ui.Container>
      </Ui.Section>
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.shape({}),
};

export const pageQuery = graphql`
  query {
    projects: allMdx {
      nodes {
        body
        fields {
          slug
        }
        frontmatter {
          title
          slug

          description
          projectCode
          order
        }
      }
    }
  }
`;

export default Page;

export const Head = ({ location, params, data, pageContext }) => {
  return (
    <Seo
      title="Billy Graham Evangelistic Association | Other Ways To Give"
      description="Your gift today will share the Good News with others and point them to the love of our Heavenly Father."
      pathname={location.pathname}
    />
  );
};
